<template>
    <div>
      <div class="title"> <i class="el-icon-arrow-left icon" @click="to"></i>任务结算记录  </div>
       <el-card class="box-card">
          <div slot="header" class="clearfix">
              <span>筛选条件</span>
          </div>
           <el-form :model="rule"   label-width="100px" class="demo-ruleForm">
                <el-form-item label="任务名称" class="item">
                     <el-input v-model="rule.taskName" style="width:300px" ></el-input>
                 </el-form-item>
                  <el-form-item label="订单号" class="item">
                     <el-input v-model="rule.batchNo" style="width:300px" ></el-input>
                 </el-form-item>
                  <el-form-item label="订单状态" class="item">
                     <el-select v-model="rule.orderState" placeholder="请选择" style="width:160px" clearable>
                        <el-option
                            v-for="item in tranList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                 </el-select> 
                 </el-form-item>
                  <el-form-item label="文件名称" class="item" label-width="120px">
                     <el-input v-model="rule.fileName" style="width:300px"></el-input>
                 </el-form-item>
                  <el-form-item label="创建时间" class="item" >
                     <el-date-picker
                        value-format="yyyy-MM-dd"
                        v-model="value1"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                 </el-form-item>
                  <el-form-item label="提交时间" class="item">
                     <el-date-picker
                        value-format="yyyy-MM-dd"
                        v-model="value2"
                        type="daterange"
                        range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
                    </el-date-picker>
                 </el-form-item>
                 <el-button type="primary" style="margin-left:40px;width:100px" @click="getDataList(1)">查询</el-button> 
        </el-form>
                                   
      </el-card>
       <el-card class="box-card">
          <div slot="header" class="clearfix">
              <span>任务结算记录</span>
          </div>
          <div>
    <el-table
       v-loading="load"
      :header-cell-style="{background:'#9e99b9',color:'#ffff'}"
      :data="tableData"
      style="width: 100%;margin-top: 10px">
      <el-table-column
        header-align="center"
        align="center"
        prop="taskName"
        label="任务名称"
        >
      </el-table-column>
      <el-table-column
        header-align="center"
        align="center"
        prop="batchNo"
        label="订单号（批次号）"
        >
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="orderAmount"
        width="130"
        label="订单金额">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="orderStateText"
        width="130"
        label="订单状态">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="successAmount"
        label="成功金额">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="failAmount"
        label="失败金额">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="fileName"
        label="文件名称">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        prop="submitter"
        width="130"
        label="提交人">
      </el-table-column>
       <el-table-column
       header-align="center"
        align="submitter"
        prop="submitTime"
        width="130"
        label="提交时间">
      </el-table-column>
       <el-table-column
       header-align="center"
        align="center"
        prop="orderCreateTime"
        width="130"
        label="订单创建时间">
      </el-table-column>
      <el-table-column
       header-align="center"
        align="center"
        label="操作">
        <template slot-scope="scope">
          <!-- <el-button  type="text" size="small"  @click="edit(scope.row)">编辑 </el-button> -->
          <el-button  type="text" size="small" v-if="scope.row.orderState==200001" @click="edit(scope.row)">编辑 </el-button>
           <el-button  type="text" size="small" v-if="scope.row.orderState!=200001" @click="seeDetails(scope.row.orderId)">查看明细</el-button>
          <el-button  type="text" size="small" v-if="scope.row.orderState==200001" @click="remove(scope.row.orderId)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
     <el-pagination
            background
            @size-change="sizeChangeHandle"
            @current-change="currentChangeHandle"
            :current-page="currPage"
            :page-sizes="[10, 20,30, 50, 100]"
            :page-size="pageSize"
            :total="totalCount"
            class="text_center"
            layout="total,  prev, pager, next, sizes,jumper"
             style="text-align:right;margin-top:30px"
     ></el-pagination> 
          </div>
      </el-card>
    </div>
</template>
<script>
import {getDownBoxByType,SettlementPageList,deleteOrderSettlement} from  "@/api/task.js";
export default {
    name: '',
    components: {},
    data() {
      return {
       load:false,
       tranList:[],
       tran:"" ,//交易账户 
       value1:[],//日期
       value2:[],//日期
       tableData: [],
        pageSize: 10,
        totalCount: 0,//数据长度
        currPage: 1,
        rule:{
            taskName:"",
            batchNo:"",
            orderState:"",
            fileName:""
        }
      }
    },
    created(){
    },
    mounted(){
      this.dropTran()
      this.getDataList()
    },
     methods:{
      to(){
          this.$router.go(-1)
      },
      dropTran(){
          // 结算标准
         getDownBoxByType({
          type:"ORDER_BATCH_STATUS"
        }).then((data=>{
         if (data && data.code==200) {
            this.tranList=data.data
            }
        }))
      },
    // 列表
    getDataList(a){
        this.load=true
       if (a) {  //判断是不是搜索
         this.currPage=1 
         this.pageSize=10
          
        }
        var createStartTime=""
        var createEndTime=""
        var submitStartTime=""
        var submitEndTime=""
        if (this.value1!="") {
          createStartTime=this.value1[0]+" 00:00:00"
          createEndTime=this.value1[1]+" 23:59:59"
        }
        if (this.value2!="") {
          submitStartTime=this.value2[0]+" 00:00:00"
           submitEndTime=this.value2[1]+" 23:59:59"
        }
        let datavalue={
          "taskName": this.rule.taskName,
          "taskId": "",
          "batchNo": this.rule.batchNo,
          "orderState":this.rule.orderState,
          "fileName": this.rule.fileName,
          "createStartTime": createStartTime,
          "createEndTime": createEndTime,
          "submitStartTime":submitStartTime,
          "submitEndTime": submitEndTime,
          "current": this.currPage,
          "size": this.pageSize,
        }
      SettlementPageList(datavalue).then((data=>{
           this.load=false
          this.tableData=data.data.records
          this.totalCount=data.data.total
      }))
    },
     // 每也数
      sizeChangeHandle(val) {
        this.pageSize = val;
        this.currPage = 1;
        this.getDataList();
      },
      // 当前页
      currentChangeHandle(val) {
        this.currPage = val;
        this.getDataList();
      },
      // 删除
      remove(a){
         deleteOrderSettlement(a).then((data=>{
         if (data && data.code==200) {
              this.getDataList();
            }
        }))
      },
      // 查看详情
      seeDetails(a){
        this.$router.push({name:"/task/details",query:{orderId:a}})
      },
      // 编辑
      edit(a){
        this.$router.push({name:"/task/taskSettlement",query:{orderId:a.orderId,taskId:a.taskId}})
      }

  }
}
</script>
<style scoped>
.text{
    font-size: 13px;
    display: inline-block;
    margin-left: 20px;
}
.title{
    font-size: 16px;
    height: 50px;
    line-height: 50px;
    background: #ffff;
    margin-top:-20px;
    margin-left:-20px;
    width: 105%;
    text-indent: 20px;
}
.icon{
    font-size: 30px;
    transform: translateY(5px);
     color: #b5b5b5;
    margin-right: 5px;
    cursor: pointer;
}
.main .box-card{
    margin-top: 10px;
}
.clearfix span{
     font-size: 15px;
}
.item{
    display: inline-block;
}
</style>
