import axios from '@/utils/http'
// -----------------任务模块接口-------------

// 根据字典类型查询字典下拉框
export const getDownBoxByType = ({type}) => {
    return axios.request({
        url: '/merchantTask/getDownBoxByType/'+type,
        params: {},
        method: 'get',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
// 获取商户交易记录
export const merchantTaskPageList = ({taskState,taskName,current,size}) => {
    return axios.request({
        url: '/merchantTask/merchantTaskPageList',
        data: {taskState,taskName,current,size},
        method: 'post',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}

// 查询商户合同所属行业级联下拉
export const Industry = ({}) => {
    return axios.request({
        url: '/merchantTask/getMerchantContractIndustryCascade',
        params: {},
        method: 'get',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}



// 创建任务
export const createdTask = ({taskName,industryId,contractId,industryCode,ticketCategoryInfo,taskStartTime,taskEndTime,taskType,settlementCycleId,settlementStandardId,price,taskDescribe}) => {
    return axios.request({
        url: '/merchantTask/createdTask',
        data: {taskName,industryId,contractId,industryCode,ticketCategoryInfo,taskStartTime,taskEndTime,taskType,settlementCycleId,settlementStandardId,price,taskDescribe},
        method: 'post',
        isTip: false,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}

// 根据任务id获取任务详情
export const getDetail= ({taskId}) => {
    return axios.request({
        url: '/merchantTask/getMerchantTaskDetailById/'+taskId,
        params: {},
        method: 'get',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
// 签约个体工商户、任务领取记录列表
export const PageList = ({taskId,name,idCard,receiveType,creditCode,receiveState,type,current,size}) => {
    return axios.request({
        url: '/merchantTask/merchantTaskSignedPageList',
        data: {taskId,name,idCard,receiveType,creditCode,receiveState,type,current,size},
        method: 'post',
        isTip: false,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}

// 下载任务模板
export const downloadExcel= ({taskId}) => {
    return axios.request({
        url: '/merchantTask/downloadExcelTemplate/'+taskId,
        params: {},
        method: 'get',
        responseType:'blob',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}

// 解除个体工商户任务签约
export const deleted= ({taskId,businessId}) => {
    return axios.request({
        url: '/merchantTask/relieveTaskSign/'+taskId+"/"+businessId,
        params: {},
        method: 'put',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}

// 临时文件上传
export const tempUpload= ({params}) => {
    return axios.request({
        url:"/file/tempUpload",
        data: params,
        method: 'post',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}

// 订单明细列表分页查询
export const uploadOrderExcel= (param) => {
    return axios.request({
        url:"/order/uploadOrderExcel",
        params: param,
        method: 'post',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}

// 订单明细列表分页查询
export const taskOrderItemPageList= (data) => {
    return axios.request({
        url:"/order/taskOrderItemPageList",
        data: data,
        method: 'post',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
// 根据明细id删除当前人员
export const deleteOrder= (itemId) => {
    return axios.request({
        url:"/order/deleteOrderItemByItemId/"+itemId,
        params: "",
        method: 'DELETE',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
// 编辑订单明细人员数据
export const edit= (datavalue) => {
    return axios.request({
        url:"/order/editOrderItemByItemForm",
        data: datavalue,
        method: 'post',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
// 数据验证
export const numValidation= (orderId) => {
    return axios.request({
        url:"/order/orderDataValidationResult/"+orderId,
        params: "",
        method: 'get',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
// 根据订单id获取结算单数据
export const SettlementDate= (orderId) => {
    return axios.request({
        url:"/order/orderSettlementDateByOrderId/"+orderId,
        params: "",
        method: 'get',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
// 下载结算单
export const SettlementImag= (orderId) => {
    return axios.request({
        url:"/order/downOrderSettlementImage/"+orderId,
        params: "",
        method: 'get',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
        responseType: 'arraybuffer'
    })
}
// 任务管理--结算记录
export const SettlementPageList= (datavalue) => {
    return axios.request({
        url:"/order/orderSettlementPageList",
        data: datavalue,
        method: 'post',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
// 上传结算单文件
export const SettlementFile= ({orderId,fileId}) => {
    return axios.request({
        url: '/order/uploadOrderSettlementFile/'+orderId+"/"+fileId,
        params: {},
        method: 'get',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
// 订单提交申请
export const orderSubmitApply= (orderId) => {
    return axios.request({
        url: '/order/orderSubmitApply/'+orderId,
        params: {},
        method: 'get',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
// 上传结算单按钮操作（去往上传结算单页面
export const GoToUpload= (orderId) => {
    return axios.request({
        url: '/order/orderGoToUploadSettStep/'+orderId,
        params: {},
        method: 'put',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
// 根据订单批次id查询订单申请结算信息
export const SettInfoByOrderId= (orderId) => {
    return axios.request({
        url: '/order/getOrderApplySettInfoByOrderId/'+orderId,
        params: {},
        method: 'get',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
// 删除订单结算批次
export const deleteOrderSettlement= (orderId) => {
    return axios.request({
        url: '/order/deleteOrderSettlementRecordById/'+orderId,
        params: {},
        method: 'DELETE',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
//订单详情--订单批次信息
export const getOrderSettlemen= (orderId) => {
    return axios.request({
        url: '/order/getOrderSettlementDetailById/'+orderId,
        params: {},
        method: 'get',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
//订单详情--订单明细列表分页查询
export const DetailPageList= (datavalue) => {
    return axios.request({
        url: '/order/orderSettlementItemDetailPageList',
        data: datavalue,
        method: 'post',
        isTip: true,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}
//校验结果
export const getOrderItemCheckResult= (orderId) => {
    return axios.request({
        url: '/order/getOrderItemCheckResult/'+orderId,
        params: "",
        method: 'get',
        isTip: false,//提示 true 全局提示
        isToken: true,
        isCircle: false, //是否多次请求
    })
}